.case-study {
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 3rem 0 5rem 0;
  .container.mobile{
    display: none;
  }
  .container.desktop {
    max-width: 85%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .black-bg{
      position: fixed;
      opacity: 0;
      transition: .3s ease;
    }
    .black-bg.active {
      width: 100%;
      height: 100%;
      z-index: 100;
      top: 0;
      left: 0;
      background: #000;
      opacity: 1;
    }
    .col {
      width: 45%;
    }
    .content {
      position: relative;
      .logo {
        position: fixed;
        z-index: 99;
        width: 8vw;
        img {
          width: 100%;
        }
      }
      .sections {
        height: 100vh;
        overflow: hidden;
        position: fixed;
        .section:last-child{
          background: transparent;
        }
        .section {
          position: fixed;
          width: 35%;
          height: 100%;
          background-color: #000;
          opacity: 1;
          margin: 4rem 0;
          padding: 2rem;
          transition: 0.3s ease;
          .title {
            font-size: 2.5vw;
            font-weight: 500;
          }
          .tag {
            color: #000;
            padding: 0.5rem 1rem;
            background-color: #c1c1c1;
            width: fit-content;
            font-size: 1vw;
          }
          .data {
            p {
              margin: 1.5rem 0;
              font-size: 1vw;
            }
          }
          .metrics {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .metric {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 30%;
              .metric-img {
                padding: 2rem;
                padding-bottom: 0;
                width: 3rem;
                img {
                  width: 100%;
                }
              }
              .content {
                .title {
                  p {
                    font-size: 1rem;
                    text-align: center;
                    font-weight: 500;
                  }
                }
                .value {
                  p {
                    text-align: center;
                    font-size: 1.75rem;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
    .images {
      .image {
        margin-bottom: 2rem;
        img {
          width: 100%;
          transition: 0.5s ease;
        }
      }
      .image:first-child,
      .image:nth-child(2),
      .image:nth-child(3) {
        img {
          width: fit-content;
          z-index: 999;
          transform: scale(0, 4);
          transform-origin: 0% 50%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .image:first-child{
        img{
          z-index: 1003;
        }
      }
      .image:nth-child(2){
        img{
          z-index: 1002;
        }
      }
      .image:nth-child(3){
        img{
          z-index: 1001;
        }
      }
      .image.active {
        img {
          transform: scale(2);
          height: 100%;
        }
      }
      .image.final {
        img {
          width: 100%;
          position: relative;
          opacity: 1;
          transform: scale(1);
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width : 992px) {
  .case-study{
    padding-top: 0;
    .container.desktop{
      display: none;
    }
    .container.mobile{
      .logo{
        img{
          width: 6rem;
        }
      }
      display: block;
      img{
        width: 100%;
      }
      display: block;
      .section{
        margin: 2rem;
        .title{
          font-size: 1.75rem;
          font-weight: 600;
        }
        .tag{
          color: #000;
          padding: .5rem;
          background-color: #fff;
          width: fit-content;
          margin: 1rem 0;
        }
        .data{
          p{
            margin-bottom: 1rem;
          }
        }
        .metrics{
          margin: 2rem 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .metric{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2rem 0;
            .metric-img{
              img{
                width: 2rem;
              }
            }
            .content{
              p{
                text-align: center;
              }
              .title{
                p{
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
        .images{
          margin: 1rem;
          .image{
            margin: 2rem 0;
            transform: translateY(5%);
          }
        }
      }
    }
  }
}