.navbar.desktop {
  padding: 0rem 4rem;
  // background-color: orange !important;
  height: 8.5vw;
  .contact-container {
    padding: 0.5rem 0;
    p {
      text-align: right;
      a {
        font-weight: 600;
        font-size: 0.75vw;
        transition: 0.3s ease;
      }
    }
    p.white {
      a {
        color: #e0e0d7;
      }
    }
    p.black {
      a {
        color: #000;
      }
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
    // background-color:  red;
    height: 3vw;
    // margin-top: 0.8vw;
    .nav-links {
      // background-color:  red;
      // height: 5vw;
      width: 50%;
      ul {
        display: flex;
        justify-content: space-around;
        li {
          padding: 0 0.5rem;
          font-size: 1rem;
          font-weight: 500;
          a {
            transition: 0.3s ease;
            position: relative;
            display: inline-block;
            font-size: 1vw;
          }
          a:after {
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            background: #000;
            transition: width 0.2s ease 0s, left 0.2s ease 0s;
            width: 0;
          }
          a:hover:after {
            width: 100%;
            left: 0;
          }
        }
        li.white {
          a:after {
            background: #e0e0d7;
          }
        }
        li.white {
          a {
            color: #e0e0d7;
          }
        }
        li.black {
          a {
            color: #000;
          }
        }
      }
    }
    .logo {
      img {
        width: 10vw;
      }
    }
  }
}
.navbar.black {
  // background-color: ;
  background-color: #e0e0d7;

  // background-color: black;
  
  // set the background initial color to black ...
  // background-color: ;
}
.navbar.white {
  background-color: #000;

}
.navbar.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .navbar.mobile {
      // display: flex !important;
      // justify-content: space-around !important;
      // background-color: red !important;
      // align-items: center;
      // margin-left: auto !important;
      // margin-right: auto !important;
      // padding: 1rem 1.5rem !important;
    }
  }

  .navbar.mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;

    .ham-menu {
      z-index: 9999;
      .ham-icon {
        span {
          display: block;
          width: 1.75rem;
          height: 2px;
          margin: 0.25rem;
          background-color: #e0e0d7;
          transition: 0.2s ease;
        }
      }
      .ham-icon.black {
        span {
          background-color: #000;
        }
      }
      .ham-icon.white {
        span {
          background-color: #e0e0d7;
        }
      }
    }
    .ham-menu.active {
      .ham-icon {
        span {
          opacity: 1;
          transform: rotate(45deg);
          margin: 0;
          background-color: #000;
        }
        span:nth-last-child(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
        span:nth-last-child(3) {
          transform: rotate(-45deg) translate(-3px, 2px);
        }
      }
    }
    @supports (-webkit-touch-callout: none) {
      .logo{
      display: flex;
      // background-color: red;
      width: 100vw !important;
      }
    }
    .logo {
      display: flex;
      // background-color: red;
      width: 100vw;
      justify-content: center;



      @supports (-webkit-touch-callout: none) {
        img {
          width: 8vw;
          display: flex !important;
          justify-content: center;
          // background-color: black;
          align-items: center;
          position: relative;
          // left: 12vw;
        }
      }
      img {
        width: 25vw;
        margin-left: auto !important;
        display: block;
      }
    }
    .contact {
      opacity: 0;
      p {
        font-size: 0.1rem;
        text-align: right;
      }
    }
    .contact.black {
      p {
        a {
          color: #000;
        }
      }
    }
    .contact.white {
      p {
        a {
          color: #e0e0d7;
        }
      }
    }
    .side-bar-menu {
      z-index: 9999;
      position: absolute;
      transform: translateX(-100%);
      transition: 0.3s ease;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      background-color: #e0e0d7;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      ul {
        margin: 3rem 0;
        li {
          a {
            color: #000;
            display: block;
            width: 100%;
            text-align: center;
            padding: 1rem 0;
            font-size: 1.25rem;
            font-weight: 600;
          }
        }
      }
      .social-icons {
        padding: 0 3rem;
        ul {
          display: flex;
          justify-content: space-between;
          li{
            a{
              img{
                width: 2rem;
              }
            }
          }
        }
      }
    }
    .side-bar-menu.active {
      transform: translateX(0);
    }
  }
  .navbar.desktop {
    display: none;
  }
}
