// animation code from here ..

.animation-container {
  background-color: white;
  display: flex;
  position: absolute;
  flex-direction: column;
  color: black;
  overflow: hidden !important;
  // margin-top: -10vw;
  width: 100vw;
  height: 100vh;
  z-index: 999 !important;
  justify-content: center;
  align-items: center;
  .animation-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    // background-color: red;
    overflow: hidden !important;
    text-align: center;
    position: relative;

    // initial clip path is here.. whole width is gonna cover
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

    // clip-path: polygon(40% 35%, 60% 35%, 60% 50%, 0% 100%);  the initial one point target

    // the final clip path value ....

    // clip-path: polygon(0% 0%, 65% 38%, 65% 53%, 35% 53%);
    // clip-path: polygon(0% 0%, 100% 0%, 65% 53%, 35% 53%);  right top
    // clip-path: polygon(0% 0%, 100% 0%, 65% 53%, 0% 100%);  left bottom
    // clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);   right bottom

    .imageContainer {
      // background-color: white;
      position: absolute !important;
      overflow: hidden !important;
      left: 50%;
      margin-top: -2vw;
      top: 48%;
      // top: 50%;
      transform: translate(-50%, -50%);
      width: 4.3vw;
      margin-bottom: 0vw;
      // background-color: red;
      clip-path: polygon(0% 0%, 100% 0%, 60% 100%, 35% 100%);
      // width: 26vw;
      // height: 0vw;
      img {
        width: 25vw;
      }
    }
  }
}

.Universal-Home {
  background-color: black;
  color: white;
  // display: none;

  .animation-continuation-layer {
    display: flex;
    background-color: black;
    // width: 100vw;
    justify-content: center;
    align-items: center;
    // height: 100vh;

    img {
      width: 25.2vw;
      position: absolute;
      top: 44%;
      // margin-top: -15vw;
    }
    // position: absolute;
  }

  // header styling only ..
  .Header {
    padding-top: 5vw;
    opacity: 0;
    .scroll-Content {
      z-index: 999 !important;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      // background-color: red;

      .scroll-btn {
        margin: 0 4rem;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          transform: rotate(-90deg);
          width: fit-content;
        }
        img {
          margin-top: 1rem;
          width: 1rem;
          margin-left: 1rem;
        }
      }

      .content {
        display: flex;
        text-align: left;
        width: fit-content;
        padding: 3vw 0vw 0vw 2vw;

        p {
          font-weight: 400 !important;
          display: flex;
          flex-direction: column;
          // text-align: justify;
          width: 75vw;
          line-height: normal;
          word-spacing: 0.1vw;
          font-size: 1.9rem;
          // font-size: 2vw;
          span {
            font-size: 1.8vw;
          }
        }
      }
    }
    .bg-video {
      display: flex;
      margin-top: -7vw;
      width: 75vw;
      background-color: white;
      opacity: 0.4;
      height: fit-content;
      margin-left: auto;
      margin-right: auto;
      .video-container {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 56.25%; /* 16:9 aspect ratio, adjust as needed */
      }

      .video-container > div {
        position: absolute;
        z-index: unset;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .Content-Cards {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;

      .card {
        display: flex;
        // justify-content: space-between;
        flex-direction: column;
        // cursor: pointer;
        border: 1px solid gray;
        width: 22vw;
        overflow: hidden;
        background-color: black;
        position: relative;
        height: 55vh;
        @media screen and (min-height : 720px) {
          height: 50vh;
        }
        h1 {
          padding: 2vw 0vw 4vw 2vw;
          font-weight: 400;
          font-size: 2vw;
          // height: vw;
        }
        p {
          padding: 0vw 1.5vw 0vw 1.5vw;
          font-size: 0.8vw;
          // background-color: red;
          line-height: 1.9vw;
          height: fit-content;
        }
        .arrow {
          border-top: 1px solid gray;
          padding: 1.5rem 0;
          margin-top: auto;
          display: flex;
        }
      }
      .card:hover {
        // border: 2px solid rgb(0, 45, 80); /* Reset border for all divs */
        transition-duration: 0.2s;
      }

      // .card:hover + .my-div {
      //   // border: 2px solid rgb(169, 218, 255); /* Reset border for adjacent divs */
      // }

      // .card:hover ~ .my-div {
      //   // border: 2px solid rgb(169, 218, 255); /* Reset border for subsequent divs */
      // }
    }
  }

  //image slider and brands styling here ..
  .image-slider-Brands {
    .slider {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: black;
      color: white;
      padding: 6rem 0vw;

      .container {
        width: 100vw;
        overflow-x: hidden !important;
        padding: 1vw 0vw;
        margin: 0 auto;
      }

      .heading {
        font-size: 2vw;
        font-weight: 600;
        text-align: left;
        padding: 0vw 0vw 0vw 5vw;
      }

      .swiper_container {
        height: 36vw;
        padding: 2rem 0;
        position: relative;
      }

      .swiper_container .carosel-card {
        display: flex;
        flex-direction: column;
        transition-property: transform;
        transition-duration: 3s;
        transition-timing-function: ease-in-out;
        .card-content {
          background-color: black;
          min-width: 20vw;
          max-width: fit-content;
          padding: 0.5vw 1.5vw;
          margin-left: auto;
          margin-right: auto;
          margin-top: -4vw !important;
          z-index: 5;
          display: flex;
          text-align: left;
          flex-direction: column;
          justify-content: flex-start;
          box-shadow: 2px 2px 6px black;
          bottom: auto;
        }
      }
      .swiper-slide {
        width: 28vw;
        height: 30vw;
        display: flex;
      }
      .swiper-slide {
        filter: blur(0px);
      }
      .swiper-slide.swiper-slide-active {
        filter: none;
      }
      @media (max-width: 760px) {
        .swiper_container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 160vw;
        }
        .swiper-slide {
          width: 100vw !important;
          height: 120vw !important;
        }
        .swiper-slide img {
          width: 100vw !important;
          height: 120vw !important;
        }
      }
      .swiper-slide img {
        width: 45vw;
        height: 30vw;
        box-shadow: 2px 2px 15px 12px black;
        filter: grayscale(0.3);
        object-fit: cover;
      }
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        display: none;
      }
      .slider-controler {
        position: relative;
        bottom: 0.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .slider-controler .swiper-button-next {
        left: 58% !important;
        transform: translateX(-58%) !important;
      }
      @media (max-width: 990px) {
        .slider-controler .swiper-button-next {
          left: 70% !important;
          transform: translateX(-70%) !important;
        }
      }
      @media (max-width: 450px) {
        .slider-controler .swiper-button-next {
          left: 80% !important;
          transform: translateX(-80%) !important;
        }
      }
      @media (max-width: 990px) {
        .slider-controler .swiper-button-prev {
          left: 30% !important;
          transform: translateX(-30%) !important;
        }
      }
      @media (max-width: 450px) {
        .slider-controler .swiper-button-prev {
          left: 20% !important;
          transform: translateX(-20%) !important;
        }
      }
      .slider-controler .slider-arrow {
        width: fit-content;
        left: 42%;
        transform: translateX(-42%);
        filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
      }
      .slider-controler .slider-arrow ion-icon {
        font-size: 2rem;
        color: white;
      }
      .slider-controler .slider-arrow::after {
        content: "";
      }
      .swiper-pagination {
        position: relative;
        width: 15rem !important;
        bottom: 1rem;
      }
      .swiper-pagination .swiper-pagination-bullet {
        filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
      }
      .swiper-pagination .swiper-pagination-bullet-active {
        background: white;
      }
    }
    .Brands-quote {
      display: flex;
      justify-content: space-between;
      padding: 6vw 0vw 0vw 4vw;
      .Quotation {
        padding: 3vw 5vw 0vw 3vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        text-align: left;
        width: 35vw;
        margin-top: -2vw;
        p {
          margin: 0 3rem;
        }
        .arrows {
          border-top: 2px solid white;
          display: flex;
          justify-content: flex-end;
          p {
            width: fit-content;
            color: gray;
            cursor: pointer;
            font-size: 1.2vw;
            padding: 2vw 0;
            margin: 0 2rem;
          }
        }
        .marks {
          width: 2vw;
          height: 2vw;
          display: flex;
          margin-left: -2vw;
          margin-bottom: -2vw;
        }
        p {
          font-weight: 500;
          font-size: 1.1vw;
          padding: 1.2vw;
          margin: 3rem 3rem 3rem 0;
          li {
            font-size: 1.1vw;
          }
        }
      }
      .Brand-card {
        background-color: white !important;
        color: black;
        width: fit-content;
        margin: 0;
        padding: 4.8vw 4vw 4.5vw 2.5vw;
        h1 {
          width: fit-content;
          // background-color: red;
          line-height: 3.5vw;
          margin: 0;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          font-size: 2vw;

          li {
            display: block;
            // background-color: red;
            // border: 1px solid black;
            overflow: hidden;
            // animation: reveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
          }
        }

        .brands {
          margin-top: 5vw;
          img {
            width: 10vw;
          }
          .spacer {
            margin: 0vw 4vw;
          }
        }
      }
    }
  }
  //compannies categories..
  .company-categories {
    padding: 4vw 0vw;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-around;

      text-align: left;
      padding: 2vw 1.5vw;
      h1 {
        width: 20vw;
        font-size: 2vw;
        font-weight: 600;
      }
      p {
        width: 45vw;
        font-weight: 500;
        li {
          font-size: 1.2vw;
          line-height: 2vw;
        }
      }
    }
    .categories-cards {
      padding-top: 6vw;
      .row1 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 4vw 0vw;
        .card {
          // cursor: pointer;
          display: flex;
          margin: 0vw 2vw;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;

          img {
            width: 4vw;
            padding: 0vw 0vw 0.5vw 0vw;
            transition: transform 0.2s ease;
            height: 4vw;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }

      .row2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2vw 0vw;
        .card {
          display: flex;
          margin: 0vw 2vw;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;

          img {
            width: 4vw;
            height: 4vw;
            padding: 0vw 0vw 0.5vw 0vw;
            transition: transform 0.2s ease;
          }

          img:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .Get-In-Touch {
    padding-top: 4vw;

    .mobile-contact-us-form {
      display: none;
    }
    header {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      flex-direction: column;

      p {
        font-size: 2.5vw;
        padding: 0.5vw 0vw;
        font-weight: 600;
      }
      button {
        margin: 3vw 0vw 10vw 0vw;
        font-size: 0.8vw;
        font-weight: bolder;
        padding: 0.5vw;
        border-radius: 0.2vw;
        border: none;
        cursor: pointer;
        background-color: #ded7aa;
        transition: transform 0.2s ease;
      }
      button:hover {
        transform: scale(1.1) !important;
      }
    }

    .map {
      filter: grayscale(100%);
      opacity: 0.8 !important;
      iframe {
        display: block;
      }
    }
  }

  .slide-item {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }

  .slide-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slick-center .slide-item {
    transform: scale(1.1); /* Adjust the zoom level as needed */
  }
}

// text reveal animation;

@keyframes reveal {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

// media queries from here..

@media screen and (max-width: 768px) {

  .animation-container {
    .animation-layer {
      .imageContainer {
        top: 5%;
        
        img{
          // width: 80vw;
        }
      }
    }
  }
  .Universal-Home {
    .Header {
      padding: 3rem 0;
      .bg-video {
        width: 90%;
        .video-container {
          padding: 0;
          height: 50vh;
          video {
            object-fit: cover;
          }
        }
      }
      .Content-Cards {
        margin-top: -1%;
        flex-direction: column;
        .card {
          width: 75vw;
          height: fit-content;
          flex-direction: row;
          align-items: center;
          padding: 1rem;
          .col:first-child {
            width: 37%;
          }
          .col:nth-child(2) {
            width: 60%;
          }
          h1 {
            text-align: center;
            font-size: 1.2rem;
            width: 100%;
            font-weight: 500;
            padding: unset;
            height: fit-content;
          }
          p {
            padding: 0.5vw 0vw 1.5vw 0vw;
            font-size: 0.75rem;
            line-height: normal;
          }

          .arrow {
            display: none;
          }
        }
      }
      .scroll-Content {
        justify-content: space-between;
        .content {
          padding: 0 1rem;
          p {
            margin-right: 1rem;
            width: fit-content;
            line-height: 6vw;
            word-spacing: 0.2vw;
            // background: red;
            display: flex;
            text-align: justify;
            position: relative;
            // right: 4.5vw;
            font-size: 0.9rem;
            span {
              font-size: 0.9rem;
            }
          }
        }
        .scroll-btn {
          margin: 0;
          position: relative;
          display: flex;
          top: 42vw;

          img {
            width: 0.5rem;
            margin-left: 1.2rem;
          }
        }
      }
    }

    .swiper-wrapper {
      height: 85%;
    }

    .company-categories {
      .header {
        flex-direction: column;
        h1 {
          width: fit-content;
          font-size: 6vw;
          padding: 3rem 0;
          margin-top: 4vw;
        }
        p {
          width: 100vw;
          font-weight: 400;
          display: none;
          margin: 5vw auto;
          padding: 1vw 0vw 0vw 6vw;
          // text-align: justify;
          li {
            // background-color: red;
            font-size: 1rem;
            line-height: 1;
            text-align: center;
          }
        }
      }
      .categories-cards {
        .row1 {
          // display: none;
          // width: 2vw !important;
          flex-wrap: wrap;

          .card {
            cursor: pointer;
            display: flex;
            margin: 3vw 7vw;
            justify-content: center;
            align-items: center;
            width: 19vw;
            text-align: center;
            flex-direction: column;

            img {
              width: 10vw;
            }

            p {
              font-size: 2.5vw;
            }
            img {
              width: 10vw;
              height: 10vw;
            }
          }
        }

        .row2 {
          flex-wrap: wrap;
          .card {
            cursor: pointer;
            display: flex;
            margin: 3vw 7vw;
            justify-content: center;
            align-items: center;
            width: 19vw;
            text-align: center;
            flex-direction: column;

            img {
              width: 10vw;
            }

            p {
              font-size: 2.5vw;
            }
            img {
              width: 10vw;
              height: 10vw;
            }
          }
        }
      }
    }

    .image-slider-Brands {
      overflow-x: hidden !important;
      margin-top: 15vw;
      .Brands-quote {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Quotation {
          width: 90vw;
          // background-color: red;
          padding-left: 10vw;

          p {
            width: 80vw;
            font-size: 1rem;
            li {
              // background-color: black;
              line-height: 5.5vw;
            }
          }
        }

        .Brand-card {
          background-color:  white;
          margin-left: auto;

          .brands {
            .spacer {
              margin: 0vw 6vw;
            }
            img {
              width: 21vw;
            }
          }

          h1 {
            line-height: 8vw;
            font-size: 3.6vw;
            width: fit-content !important;
            span {
              // color:  orange;
              // background-color: black;
              width: 100%;
            }
          }
        }
      }
      .slider {
        // background-color: white;
        .heading {
          font-size: 5.5vw;
          font-weight: 600;
          // background-color: red;
          margin-bottom: -15vw;
        }
        // .swiper-slide {
        //   // img {
        //   //   // height: unset !important;
        //   // }
        // }
      }
    }

    .Get-In-Touch {
      .map {
        display: none;
      }
      .mobile-contact-us-form {
        display: block;
        margin-top: 15vw;
        padding: 5vw 1vw;
        background-color: white;
        color: black;

        header {
          h1 {
            font-weight: 600;
            font-size: 5vw;
          }
        }

        .form-inputs {
          .col {
            display: flex;
            // border: 1px solid black;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            font-size: 2.8vw;
            margin: 6vw 0vw;

            .row {
              // border: 1px solid red;
              input {
                background-color: whitesmoke;
                padding: 2vw;
                border-radius: 1vw;
                margin-top: 1vw;
                width: 40vw;
              }

              textarea {
                background-color: whitesmoke;
                border: none;
                padding: 2vw;
                border-radius: 1vw;
              }
            }
          }

          .form-submit-btn {
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            button {
              padding: 1vw 4vw;
              font-size: 3vw;
              font-weight: 600;
              border-radius: 1vw;
              border: none;
              background-color: #383100 !important;
            }
          }
        }
      }
      header {
        margin: 7vw 0vw;
        button {
          margin: 6vw 0vw 20vw 0vw;
          font-size: 3.2vw;
          font-weight: bolder;
          padding: 2.5vw;
          border-radius: 1.2vw;
          border: none;
          cursor: pointer;
          background-color: #ded7aa;
          color: black !important;
        }
        p {
          font-size: 6.5vw;
          padding: 2.5vw 0vw;
          text-align: center;
        }
      }
    }
  }
}
