.our-work {
  .head {
    display: flex;
    padding-top: 3rem;
    background-color: #000;
    .scroll-btn {
      margin: 0 4rem;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        transform: rotate(-90deg);
        width: fit-content;
        color: #fff;
      }
      img {
        margin-top: 1rem;
        width: 1rem;
        margin-left: 1rem;
      }
    }
    .description {
      .title {
        h1 {
          color: #fff;
          font-size: 3vw;
          font-weight: 500;
        }
        .body {
          margin: 3vw 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .image {
            img {
              width: 100%;
            }
            width: 45%;
          }
          .desc {
            width: 50%;
            // background-color: red;
            h1 {
              font-size: 3vw;
              width: 100%;
            }
            p{
              color: #fff;
              width: 90%;
              margin: 4rem 0;
              display: flex;
              flex-direction: column;
              font-size: 1.3vw;
              line-height: 2.5vw ;
            }
          }
        }
      }
    }
  }
  .showreel {
    background-color: #000;
    padding: 4vw 10%;
    video {
      width: 100%;
    }
  }
  .our-love {
    background-color: #000;
    padding: 5rem 0;
    p {
      font-size: 2.2vw;
      color: #fff;
      width: 80%;
      margin: auto;
      // margin-left: auto;
    }
  }
  .compassion {
    background-color: #000;
    padding: 4rem 8vw;
    display: flex;
    justify-content: space-between;
    .image {
      width: 47.5%;
      img {
        width: 100%;
      }
    }
    .description {
      width: 40%;
      display: flex;
      // justify-content: space-between;
      flex-direction: column;
      .image {
        width: 100%;
      }
      .desc {
        p {
          // background-color: red;
          margin-top: 4vw;
          display: flex;
          line-height: 2.7vw;
          flex-direction: column;
          color: #fff;
          font-size: 1.2vw;
          width: fit-content;
        }
      }
    }
  }
  .case-studies{
    margin: 2rem 4rem;
    .case-head{
      padding: 4rem 2rem;
      height: 45vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
        p{
          font-size: 1.5vw;
          font-weight: 500;
        }
        p:nth-child(2){
          font-weight: 600;
          font-size: 3vw;
        }
      }
      .preview{
        width: 15%;
        img{
          width: 100%;
          transition: .2s ease;

        }
      }
      .preview:hover{
        cursor: pointer;
      }
    }
    .logos{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .logo{
        margin: 1rem 1.5rem;
        width: 12%;
        img{
          width: 80%;
          transition: .2s ease;
        }
      }
      .logo:hover{
        img{
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
  }
  .shout {
    justify-content: center;
    .ribbon {
      height: 30vh;
      background-color: #000;
      margin-bottom: -5%;
    }
    .card {
      width: 45%;
      background-color: #e0e0d7;
      padding: 2rem;
      border-radius: 5px;
      margin: auto;
      p {
        color: #000;
        font-size: 2vw;
        width: 100%;
        padding: 3vw 0vw;
        line-height: 3.5vw;
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .our-work {
    .head {
      .scroll-btn {
        margin: 0 0.5rem;
        img {
          width: 0.6rem;
          margin-left: 0.5rem;
        }
        p {
          font-size: 1rem;
          display: flex;
          position: relative;
          right: 2vw;        }
      }
      .description {
        .title {
          h1 {
            font-size: 1.5rem;
          }
          .body {
            margin: 2rem 2rem 0 0;
            flex-direction: column-reverse;
            justify-content: start;
            .image {
              width: 100%;
            }
            .desc {
              width: 100%;
              p{
                color: #fff;
                width: 98%;
                margin: 4.2rem 0;
                // background: red;
                display: block;
                font-size: 0.75rem;
                // text-align: justify;
                line-height: 5.2vw;
              }
              h1 {
                font-size: 1rem;
                margin: 0.5rem 0;
                width: 70%;
              }
            }
          }
        }
      }
    }
    .showreel {
      padding: 3rem 0;
    }
    .our-love{
      padding: 3rem 0;
      p{
        font-size: 6vw;
        text-align: center;
        width: 90%;
        // background-color: red;
      }
    }
    .compassion{
      flex-direction: column;
      .image{
        width: 100%;
      }
      .description{
        width: 100%;
        .image{
          display: none;
        }
        .desc{
          p{
            line-height: normal;
            font-size: .75rem;
            display: flex;
            margin: 2rem 0;
            text-align: left;
          }
        }
      }
    }
    .case-studies{
      margin: 2rem 1rem;
      .case-head{
        flex-direction: column;
        padding: 1rem;
        height: fit-content;
        margin: 2rem 0;
        .title{
          p{
            font-size: 1rem;
          }
          p:nth-child(2){
            font-size: 2.5rem;
          }
        }
        .preview{
          display: none;
        }
      }
      .logos{
        justify-content: space-around;
        .logo{
          width: 35%
        }
      }
    }
    .shout{
      margin-bottom: 2rem;
      .ribbon{
        height: 0;
      }
      .card{
        padding: 2rem;
        width: 70%;
        p{
          font-size: 1rem;
          line-height: normal;
          width: 100%;
        }
      }
    }
  }
}
