@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800&display=swap');
:root{
  --swiper-theme-color : #fff !important;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
body{
  // background-color: black;
}
/* html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

@media screen and (max-width : 768px) {
  html{
    overflow-x: hidden;
  }
  body{
    overflow-x: hidden;
  }
}
html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}
li{
  list-style-type: none  !important;
}
a{
  text-decoration: none;
}
input{
  border: none;
  outline: none;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #000;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  display: none; 
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #000;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1; 
}

.scroll-to-top{
  position: fixed;
  z-index: 9999;
  opacity: 1;
  bottom: 0;
  right: 0;
  margin: 0 2rem 2rem 0;
  img{
    min-width: 3rem;
    width: 3vw;
  }
  svg{
    width: 3vw;
  }
  transition: .3s ease;
}
.scroll-to-top.hide{
  opacity: 0;
}
.scroll-to-top:hover{
  cursor: pointer;
}