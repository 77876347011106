$card-border-width: 2px;
$card-border-color: #a2a2a2;

.services {
 
  .br{
    // min-height: 100vh;
    width: 100vw;
  }
  .sticky-parent {
    height: 400vh;
    .sticky {
      .horizontal {
        display: flex;
        .dim {
          display: block;
          min-width: 100vw;
          height: 100vh;
        }
      }
      position: sticky;
      top: 0px;
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
  .head {
    background-color: #e0e0d7;
    padding-top: 3rem;
    width: 100vw;
    display: flex;
    .scroll-btn {
      margin: 0 4rem;
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        transform: rotate(-90deg);
        width: fit-content;
      }
      img {
        margin-top: 1rem;
        width: 1rem;
        margin-left: 1rem;
      }
    }
    .show-video {
      margin: 0 10rem 0 0;
      p {
        font-size: 3vw;
        font-weight: 600;
        margin: 2rem 0;
      }
      video {
        width: 100%;
        height: auto;
      }
    }
  }
  .description.mobile{
    display: none;
  }
  .description {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    // padding: 5rem 12rem;
    padding: 0 10rem;
    background-color: #e0e0d7;
    .title {
      font-size: 2.5vw;
      width: 45%;
      font-weight: 600;
    }
    .desc {
      // width: 45%;
      margin-top: 2rem;
      font-weight: 500;
      font-size: 1.5vw;
    }
  }
  .service-catlouge.mobile{
    display: none;
  }
  .service-catlouge {
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 10rem;
    background-color: #e0e0d7;
    .service-card {
      background-color: #e0e0d7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25%;
      border-top: $card-border-width solid $card-border-color;
      border-right: $card-border-width solid $card-border-color;
      border-bottom: $card-border-width solid $card-border-color;
      height: 60vh;
      transition: 0.3s ease !important;

      @media screen and (min-width: 1900px) {
        height: 60vh;
      }
      .card-footer {
        border-top: $card-border-width solid $card-border-color;
        padding: 1rem;
        img {
          width: 1.5rem;
          opacity: 0;
          transition: 0.3s ease;
        }
        transition: 0.2s ease;
      }
      .card-footer:hover {
        // cursor: pointer;
        img {
          transform: translateX(1rem);
        }
      }
      .card-title {
        padding: 1.5vw;
        font-size: 1.75vw;
        height: 25%;
        margin-top: 2rem;
        font-weight: 600;
      }
      .card-desc {
        padding: 1.5vw;
        font-size: 0.85vw;
        font-weight: 500;
      }
    }
    // .service-card:hover {
    //   // border: 2px solid #0000ff !important;
    // }
    .service-card:first-child {
      border-left: $card-border-width solid $card-border-color;
    }
  }
  .clients {
    height: 100vh;
    .title {
      padding: 3rem 0;
      align-items: center;
      justify-content: center;
      background-color: #e0e0d7;
      h1 {
        text-align: center;
        font-size: 2.75vw;
        font-weight: 600;
      }
      .desc {
        margin: 1rem 0;
        p {
          width: 50%;
          margin: auto;
          text-align: center;
        }
      }
    }
    .client-logos {
      background-color: #fff;
      padding: 1rem 9rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        object-fit: contain;
        width: 10%;
        height: auto;
        padding: 2.5rem 2rem;
      }
    }
  }
  .shout {
    justify-content: center;
    .ribbon {
      height: 30vh;
      background-color: #e0e0d7;
      margin-bottom: -5%;
    }
    .card {
      width: 40%;
      background-color: #000;
      padding: 3rem;
      border-radius: 5px;
      margin: auto;
      p {
        color: #fff;
        font-size: 2vw;
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .services {
    .desktop{
      display: none !important;
    }
    .br{
      
      min-height: fit-content;
    }
    .sticky-parent{
      height: fit-content;
      .sticky{
        .horizontal{
          .dim{
            display: none;
          }
        }
      }
    }
    .head {
      // flex-direction: column;
      width: fit-content;
      padding-right: 1rem;
      .scroll-btn {
        margin: 0;
        img {
          width: 0.5rem;
          margin-left: 1.2rem;
        }
      }
      .show-video {
        margin: 0;
        p {
          font-size: 1.5rem;
        }
        video {
          height: 50vh;
          object-fit: cover;
        }
      }
    }
    .description.mobile{
      display: flex;
    }
    .description {
      padding: 3rem 2rem;
      flex-direction: column;
      .title {
        width: 100%;
        p {
          font-size: 1.75rem;
        }
      }
      .desc {
        padding: 1rem 0;
        width: 100%;
        font-weight: 400;
        font-size: 1rem;
      }
    }
    .service-catlouge.mobile{
      display: flex;
    }
    .service-catlouge {
      padding: 2rem;
      height: fit-content;
      flex-wrap: wrap;
      .service-card {
        border: $card-border-width solid $card-border-color;
        width: 48.5%;
        height: 30vh;
        .card-title {
          font-size: 1rem;
          text-align: center;
          height: 35%;
          // margin-top: 4rem;
        }
        .card-desc {
          display: none;
        }
        .card-footer {
          padding: 0.5rem;
          border: none;
          img {
            width: 0.75rem;
          }
        }
        .card-footer:hover {
          // cursor: pointer;
          img {
            transform: translateX(0);
          }
        }
      }
      .service-card:hover {
        border: 2px solid $card-border-color !important;
      }
      #card-1 {
        transform-origin: 0% 0%;
      }
      #card-2 {
        transform-origin: 100% 0%;
      }
      #card-3 {
        transform-origin: 0% 100%;
      }
      #card-4 {
        transform-origin: 100% 100%;
      }
      .service-card.active {
        transform: scale(2);
        z-index: 99;
        // border: 1px solid #0000ff !important;
        .card-title {
          p {
            font-size: 1rem;
          }
        }
        .card-desc {
          display: block;
          padding: .5rem;
          p {
            font-size: 0.45rem;
            text-align: center;
          }
        }
        .card-footer {
          img {
            opacity: 0;
          }
        }
      }
    }
    .clients {
      height: fit-content;
      .title {
        padding: 2rem 1rem;
        h1 {
          font-size: 1.5rem;
        }
        .desc {
          p {
            width: 85%;
            font-size: 1rem;
            display: flex;
            text-align: justify;
          }
        }
      }

      .client-logos {
        padding: 3rem 1rem;
        justify-content: space-around;
        img {
          width: 20vw;
          height: 20vw;
          padding: 0.85rem;
        }
      }
    }
    .shout {
      .ribbon {
        display: none;
      }
      background-color: #d1d1d1;
      padding: 1.5rem 0;
      .card {
        width: fit-content;
        margin: 0 1.5rem;
        p {
          font-size: 1rem;
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
