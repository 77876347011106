.footer.desktop {
  margin: 4rem 10rem 2rem 10rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #c1c1c1;
  .logo {
    margin: 2rem 0;
    img {
      width: 10vw;
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    .column {
      width: 22.5%;
      .title {
        margin-bottom: 2.5rem;
        font-weight: 500;
        font-size: 1.2vw;
      }
      li {
        a {
          color: #000;
          font-size: 1vw;
        }
        a:hover {
          color: #3a3a3a;
        }
        padding: .5rem 0;
      }
      .social-icons {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        width: 85%;
        .social-icon {
          width: 22.5%;
          a {
            display: block;
            img {
              display: block;
              width: 2vw;
              transition: 0.2s ease;
            }
          }
        }
        .social-icon:hover {
          a {
            img {
              transform: scale(1.2);
            }
          }
        }
      }
      .form {
        width: 100%;
        .error{
          color: #f00;
          text-align: center;
          font-size: .8rem;
        }
        .input-element {
          padding: .5rem 1rem;
          input {
            font-size: .8vw;
            max-width: 100%;
            min-width: 100%;
            background-color: transparent;
            text-align: center;
          }
        }
        .input-element.tel {
          background-color: #d5d5d58e;
          margin-bottom: .5rem;
        }
        .input-element.name {
          background-color: #d5d5d58e;
          margin-bottom: .5rem;
        }
        .input-element.submit {
          background-color: #000;
          color: #fff;
          p {
            text-align: center;
            font-size: 1vw;
          }
        }
        .input-element.submit:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.footer.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer.desktop {
    display: none;
  }
  .footer.mobile {
    display: block;
    .map {
      iframe {
        width: 100%;
        outline: none;
        border: none;
        height: 40vh;
        display: block;
      }
    }
    .bottom-bar {
      display: flex;
      background: #000;
      padding: 1rem 0.75rem;
      justify-content: space-between;
      align-items: center;
      .logo {
        img {
          width: 7rem;
          display: block;
        }
      }
      .contact {
        p {
          line-height: 1;
          a {
            color: #fff;
            font-size: 0.5rem;
          }
        }
      }
    }
    .contact-form {
      .title {
        p {
          text-align: center;
          padding: 1rem 0;
          font-size: 1.75rem;
          font-weight: 600;
        }
      }
      .body {
        padding: 1rem 2rem;
        .form {
          .error{
            color: #f00;
            text-align: center;
            font-size: .8rem;
          }
          .field-row {
            display: flex;
            justify-content: space-between;
            .field-container {
              margin: 0 0.25rem;
              width: 100%;
              label {
                font-size: 0.75rem;
              }
              .input-container {
                // width: 100%;
                background-color: #ebebeb;
                padding: 0.75rem;
                input {
                  background-color: transparent;
                  width: 100%;
                  font-size: 0.75rem;
                }
                textarea {
                  width: 100%;
                  background-color: transparent;
                  outline: none;
                  border: none;
                }
              }
              
            }
            .submit {
              width: 100%;
              outline: none;
              border: none;
              padding: 1rem;
              margin: 1rem 0;
              background-color: #000;
              color: #fff;
              p{
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
